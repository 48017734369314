@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles//animations.scss');
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  min-width: 300px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
html {
  font-family: 'Poppins', sans-serif;
}

.selected {
  background-color: rgb(54, 230, 54);
}
.unselected {
  background-color: rgb(234, 234, 234);
}

.bg-image {
  background-image: linear-gradient(to bottom, rgba(2, 2, 2, 0.8), rgba(0, 0, 0, 0.5)), url('./assets/images/main.jpg');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(3px);
  position: absolute;
  z-index: -1; /* This ensures the background is positioned below the header content */
}
@media (min-width: 992px) {
  .bg-image {
    filter: blur(9px); /* Increase the blur value for larger screens */
  }
}
.content-1 {
  background-image: linear-gradient(to bottom, #b5d5ec, #9bcfea, #7cc9e6, #56c3e0, #06bdd8);
}

.content-2 {
  background-image: linear-gradient(to right bottom, #263037, #1e2f3b, #172d40, #0f2b45, #082949);
}

.gradient {
  background-image: linear-gradient(to bottom, #b8c6cb, #95bed1, #71b4da, #48a9e4, #009dee);
}
.gradient-2 {
  background-image: linear-gradient(to bottom, #b8c6cb, #95bed1, #71b4da, #48a9e4, #009dee);
}

.config-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(23, 22, 22, 0.852) !important;
  opacity: 0;

  transform: translateY(100%);
  transition: opacity 0.1s ease-in-out, transform 0.2s ease-in-out;
}

.config-container.show {
  opacity: 1;
  transform: translateY(0%);
}

.config-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.config-overlay.show {
  opacity: 1;
}
